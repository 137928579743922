import { x } from "@xstyled/emotion";
import { Button, Container } from "anolis-ui";
import RobeHeading from "components/ui/RobeHeading";
import { FC, useState, useEffect, Fragment } from "react";

import { Link as ReactScrollLink } from "react-scroll";
import NextImage, { StaticImageData } from "next/image";

interface HeroElementProps {
  slides: HeroSlideProps[];

  priority?: boolean;

  slideDuration?: number;
  transDuration?: number;
}

export const HeroElement: FC<HeroElementProps> = ({ slides, priority, slideDuration = 12000, transDuration = 1000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => setIndex(0), [slides.length]);

  useEffect(() => {
    const t = setInterval(() => slides.length > 1 && setIndex(s => s + 1 % (slides.length + 1)), slideDuration);

    let t3: any;

    const t2 = setTimeout(() => {
      t3 = setInterval(() => setIndex(s => s === slides.length ? 0 : s), slideDuration);
    }, slideDuration + transDuration + 20);

    return () => {
      clearInterval(t);
      clearInterval(t2);
      t3 && clearInterval(t3);
    };
  }, [slideDuration, slides.length, transDuration]);

  return (
    <x.div w="100%" overflowX="hidden" backgroundColor="#000">
      <x.div
        position="relative"
        transform
        translateX={`-${index * 100}%`}
        transition={index === 0 ? undefined : `transform ${transDuration}ms`}
      >
        {slides.map((s, i) => <HeroSlide key={i} {...s} left={i !== 0 ? `${i * 100}%` : undefined} priority={priority && i === 0} />)}
        <HeroSlide {...slides[0]} left={`${slides.length * 100}%`} />
      </x.div>

    </x.div>
  );
};

interface HeroSlideProps {
  bgImage?: string | StaticImageData;
  heading: string;
  paragraph?: string;
  subheading?: string;
  button?: string;
  anchor?: string;
  priority?: boolean;
  buttonOnclick?: () => unknown;
}

const HeroSlide: FC<HeroSlideProps & { left?: string }> = ({
  bgImage,
  heading,
  paragraph,
  subheading,
  button,
  anchor,
  priority,
  buttonOnclick,
  left
}) => {
  const headingOnly = !paragraph && !button;
  return (
    <x.div
      position="relative"
      {...left && {
        position: "absolute",
        top: 0,
        left
      }}
      w="100%"
      bottom={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      h={headingOnly ? "auto" : { _: "20rem", sm: "40rem" }}
      py={headingOnly ? "6rem" : 0}
    >
      {bgImage && (
        <NextImage
          src={bgImage}
          layout="fill"
          objectFit="cover"
          priority={priority}
        />
      )}
      <x.div position="absolute">
        <Container>
          {headingOnly ? (
            <RobeHeading t="h1" as="h1" color="#fff" textTransform="capitalize">
              {heading}
            </RobeHeading>
          ) : (
            <x.h1 fontSize={{ _: "2rem", md: "5rem" }} lineHeight={{ _: "2.375rem", md: "5rem" }} color="#fff" textAlign="center">
              {heading}
            </x.h1>
          )}

          {/* same as paragraph, but keeping HeroElement as headingOnly */}
          {subheading && (
            <x.p
              color="#fff"
              mt="1rem"
              fontWeight="600"
              textAlign="center"
              fontSize="1.2rem"
            >
              {subheading}
            </x.p>
          )}

          {paragraph && (
            <x.p
              color="#fff"
              mt="1rem"
              fontWeight="600"
              textAlign="center"
              fontSize="1.2rem"
            >
              {paragraph}
            </x.p>
          )}

          {button && !anchor && (
            <x.div display="flex" justifyContent="center">
              <Button mt={{ _: "1.5rem", sm: "2rem" }} onClick={buttonOnclick}>
                {button}
              </Button>
            </x.div>
          )}
          {button && anchor && (
            <x.div display="flex" justifyContent="center">
              <ReactScrollLink
                to={anchor}
                smooth
                duration={500}
                isDynamic
                offset={-50}
                ignoreCancelEvents={false}
              >
                <Button mt={{ _: "1.5rem", sm: "2rem" }} onClick={buttonOnclick}>
                  {button}
                </Button>
              </ReactScrollLink>
            </x.div>
          )}
        </Container>
      </x.div>

    </x.div>
  );
};
