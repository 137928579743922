import { useEffect } from "react";
import TagManager, { DataLayerArgs } from "react-gtm-module";
import { environment } from "utils/environment";

export const useTagManager = (config: string | DataLayerArgs) => {
  useEffect(() => {
    environment === "prod" &&
    TagManager.dataLayer({ dataLayer: typeof config === "string" ? { event: "PageTypeEvent", pageType: config } : config });
  }, [config]);
};
