import {
  ApiContent,
  ApiProductVariantWithProductModel,
  getContentRequest,
  listProductsByCategorySlugRequest
} from "@ecommerce/core/generated";
import { x } from "@xstyled/emotion";
import { Container } from "anolis-ui";
import ProductCard from "components/cards/ProductCard";
import ProductGrid from "components/grids/Product";
import Page from "components/layouts/Page";
import { HeroElement } from "components/ui/HeroElement";
import RobeHeading from "components/ui/RobeHeading";
import hero1 from "public/static/img/hero/hero1.png";
import hero2 from "public/static/img/hero/hero2.png";
import hero3 from "public/static/img/hero/hero3.png";
import React, { FC } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useTagManager } from "utils/useTagManager";

import { ssgProps } from "../utils/ssgProps";

const IndexPage: FC<{
  mostPopular: ApiProductVariantWithProductModel[];
  newcomers: ApiProductVariantWithProductModel[];
  infoText: ApiContent | null;
}> =
  ({ mostPopular, newcomers, infoText }) => {
    const { formatMessage } = useIntl();
    useTagManager("homepage");

    return (
      <Page>
        <HeroElement
          slides={[
            {
              bgImage: hero1,
              heading: formatMessage(content.heading1),
              paragraph: formatMessage(content.paragraph1)
            },
            {
              bgImage: hero2,
              heading: formatMessage(content.heading2),
              paragraph: formatMessage(content.paragraph2)
            },
            {
              bgImage: hero3,
              heading: formatMessage(content.heading3),
              paragraph: formatMessage(content.paragraph3)
            }
          ]}
          priority
        />

        {infoText && (
          <x.h5
            textAlign="center"
            backgroundColor="robe"
            color="white"
            fontWeight="semibold"
            p="1rem"
            fontSize={{ _: "1rem", sm: "1.2rem" }}
          >
            {infoText.textValue}
          </x.h5>
        )}

        {!!mostPopular.length && (
          <Container>
            <x.div pt={{ _: "3rem", sm: "4rem" }} pb={{ _: "3rem", sm: "6rem" }} id="most-popular">
              <RobeHeading as="h2">
                <FormattedMessage id="homepage.title.top" defaultMessage="Most popular" />
              </RobeHeading>
              <ProductGrid mt={{ _: "6", sm: "12" }}>
                {mostPopular.map((productData) => (
                  <ProductCard
                    key={productData.product.id}
                    {...productData.product}
                    {...productData}
                    cover={productData.picture}
                    name={productData.product.name}
                  />
                ))}
              </ProductGrid>
            </x.div>
          </Container>
        )}

        {!!newcomers.length && (
          <Container>
            <x.div pb={{ _: "3rem", sm: "6rem" }} id="newcomers">
              <RobeHeading as="h2">
                <FormattedMessage id="homepage.title.bottom" defaultMessage="Newcomers" />
              </RobeHeading>
              <ProductGrid mt={{ _: "6", sm: "12" }}>
                {newcomers.map((productData) => (
                  <ProductCard
                    key={productData.product.id}
                    {...productData.product}
                    {...productData}
                    cover={productData.picture}
                    name={productData.product.name}
                  />
                ))}
              </ProductGrid>
            </x.div>
          </Container>
        )}
      </Page>
    );
  };

export const getStaticProps = ssgProps(async ({ ctx }) => {
  const mostPopular =
    await listProductsByCategorySlugRequest({}, "home", {
      perPage: 10000,
      sortBy: "id",
      dir: "asc",
      sortByLocale: ctx.locale ?? "en"
    })
      .catch(() => undefined);
  const newcomers =
    await listProductsByCategorySlugRequest({}, "newcomers", {
      perPage: 10000,
      sortBy: "id",
      dir: "asc",
      sortByLocale: ctx.locale ?? "en"
    })
      .catch(() => undefined);
  const infoText =
    await getContentRequest({})
      .catch(() => undefined);

  // if (!mostPopular || !mostPopular.items || !mostPopular.items.products) return { props: { products: [] } };

  return {
    props: {
      mostPopular: mostPopular?.items?.products ?? [],
      newcomers: newcomers?.items?.products ?? [],
      infoText: infoText?.enabled ? infoText : null
    }
  };
});

export default IndexPage;

const content = defineMessages({
  heading1: {
    id: "homepage.heroElement.heading1",
    defaultMessage: "Energize. Explore. Engage."
  },
  paragraph1: {
    id: "homepage.heroElement.paragraph1",
    defaultMessage: "Take your personal performance to a higher stage in the Robe athletic collection."
  },
  heading2: {
    id: "homepage.heroElement.heading2",
    defaultMessage: "Relax. Travel. Play."
  },
  paragraph2: {
    id: "homepage.heroElement.paragraph2",
    defaultMessage: "Enjoy your day off with whatever makes you happy in the Robe casual collection."
  },
  heading3: {
    id: "homepage.heroElement.heading3",
    defaultMessage: "Load. Build. Create."
  },
  paragraph3: {
    id: "homepage.heroElement.paragraph3",
    defaultMessage: "Be ready to make your production shine in the Robe technician collection."
  }
});
